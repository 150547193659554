<div *ngIf="isEnableNfePage; else notEnabledNfe">

  <p-contextMenu
    #cm
    [model]="itemsContextMenu"
    (onHide)="removeHighlightContextmenu()"
    styleClass="custom-context-menu">
  </p-contextMenu>

  <div class="container">
    <div class="container-width">
      <!-- TITULO -->
      <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
        <div class="flex-grow-1">
          <div style="width: 200px;">
            <h4 style="line-height: 2">
              NF-e
            </h4>
          </div>
        </div>
        <div style="margin-top: -10px;">
          <button pButton class="p-button-rounded p-button-text me-5 ms-4 pt-2" pTooltip="Recarrega dados da tabela" [showDelay]="500" (click)="refresh()">
            <i class="pi pi-refresh"></i>
          </button>
        </div>
      </div>

      <!-- Botões de ações -->
      <div class="d-flex mt-4">
        <div class="flex-grow-1">
          <div class="d-flex flex-row">
            <div>
              <p-inputGroup>
                <span class="p-float-label">
                  <input
                    id="filtroChaveAcessoNfe"
                    pInputText
                    style="width: 370px;"
                    [(ngModel)]="nfeRequest.chaveAcesso" />
                  <label for="filtroChaveAcessoNfe">Chave de acesso</label>
                </span>
                <button type="button" pButton icon="pi pi-times" class="p-button-outlined p-button-secondary button-grouped" (click)="limpaFiltro('chaveAcesso')"></button>
              </p-inputGroup>
            </div>
            <div>
              <p-button icon="pi pi-search" [rounded]="true" [text]="true" [raised]="true" severity="info" class="ms-4" (onClick)="onClickFiltrarChaveAcesso()"></p-button>
            </div>
            <div style="margin-left: 20px;">
              <p-selectButton
                [options]="contextOptions"
                [(ngModel)]="contextSel"
                optionLabel="icon"
                (onChange)="onChangeContextOptions($event)">
                <ng-template let-item pTemplate>
                  <i [class]="item.icon"></i>
                  <div class="ms-2">{{item.label}}</div>
                </ng-template>
              </p-selectButton>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex">
            <div class="margin-r-3">
              <button pButton type="button" icon="pi pi-cloud-download" label="Busca novas notas" class="p-button-outlined botao-custom"
                (click)="consultaWebserviceNfe()" [disabled]="selectedEmpresa == undefined">
              </button>
            </div>
            <div class="me-2">
              <button pButton type="button" class="p-button-rounded p-button-secondary" pTooltip="Download das notas selecionadas" tooltipPosition="top"
                icon="pi pi-download" (click)="downloadDFeSelecionadas()" [disabled]="selectedRows.length == 0">
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <!-- Tabela com dados -->
        <p-table #dt
          class="table-dfe"
          [value]="dfePage.content"
          [lazy]="true"
          (onLazyLoad)="lazyLoad($event)"
          [totalRecords]="dfePage.totalElements"
          [(selection)]="selectedRows"
          [selectionPageOnly]="true"
          dataKey="chaveAcesso"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="pageSizes"
          [rows]="pageSizes[0]"
          [loading]="loading"
          [paginator]="true"
          currentPageReportTemplate="{first} {{'até'}} {last} {{'de'}} {{dfePage.totalElements}} {{'entradas'}}"
          [scrollable]="true"
          scrollHeight="calc(100% - 200px)"
          [contextMenu]="cm">
          <!-- HEADER -->
          <ng-template pTemplate="header">
            <tr>
              <th style="max-width: 50px; padding-left: 1.2rem;">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="tipo" style="min-width: 90px">
                Tipo
                <p-sortIcon field="tipo"></p-sortIcon>
              </th>
              <th pSortableColumn="chaveAcesso" style="min-width: 345px">
                Chave de acesso
                <p-sortIcon field="chaveAcesso"></p-sortIcon>
              </th>
              <th pSortableColumn="numero" style="min-width: 100px">
                Número
                <p-sortIcon field="numero"></p-sortIcon>
              </th>
              <th pSortableColumn="valor" style="min-width: 100px">
                Valor
                <p-sortIcon field="valor"></p-sortIcon>
              </th>
              <th pSortableColumn="nsu" style="min-width: 100px">
                NSU
                <p-sortIcon field="nsu"></p-sortIcon>
              </th>
              <th pSortableColumn="dataEmissao" style="min-width: 100px">
                Data Emissão
                <p-sortIcon field="dataEmissao"></p-sortIcon>
              </th>
              <th pSortableColumn="origem" style="min-width: 100px">
                Origem
                <p-sortIcon field="origem"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <!-- BODY -->
          <ng-template pTemplate="body" let-dfe>
            <tr [pContextMenuRow]="dfe" (contextmenu)="onContextMenu(dfe)" class="table-row-body">
              <td style="max-width:50px">
                <p-tableCheckbox [value]="dfe" class="ml-1"></p-tableCheckbox>
              </td>
              <td>
                <span *ngIf="dfe.tipo == NFeTipo.NFE_COMPLETA" ngClass="nfe-completa">
                  {{dfe.tipo == NFeTipo.NFE_COMPLETA ? "Completa": ""}}
                </span>
                <span *ngIf="dfe.tipo == NFeTipo.NFE_RESUMO" ngClass="nfe-resumida">
                  Resumida
                </span>
              </td>
              <td>{{dfe.chaveAcesso}}</td>
              <td>{{dfe.numero}}</td>
              <td>{{dfe.valorTotal | currency:'BRL':'symbol':'1.2-2'}}</td>
              <td>{{dfe.nsu}}</td>
              <td>
                {{dfe.dataEmissao | date:'dd/MM/yyyy' }}&nbsp;<span style="color: rgb(165, 165, 165); font-size: 12px;">{{dfe.dataEmissao | date:'HH:mm:ss' }}</span>
              </td>
              <td>{{dfe.origem}}</td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="dfePage.totalElements == 0" class="mt-3 w-100 d-flex justify-content-center">
          <div>Sem dados</div>
        </div>
      </div>
        <div *ngIf="dfePage.totalElements > 0">
          <b>Selecionadas:</b> {{selectedRows.length}}
        </div>
      </div>
  </div>
</div>

<ng-template #notEnabledNfe>
  <div class="centered-container">
    <div class="centered-content">
      <img src="assets/images/danger.png" alt="Danger">
      <p>Empresa não cadastrada no módulo NF-e</p>
    </div>
  </div>
</ng-template>

<p-toast position="bottom-center"></p-toast>

<!-- Modal DANFE -->
<p-dialog
  header="DANFE"
  [modal]="false"
  [(visible)]="showModalDanfe"
  [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '85vw', height: '90vw'}"
  [draggable]="false"
  [resizable]="false">
  <div class="iframe-danfe" [ngClass]="{'h-100' : !showProgressSpinModalDanfe, 'd-none': showProgressSpinModalDanfe}">
    <iframe id="pdfViewer" [src]="base64Pdf" width="100%" height="100%"></iframe>
  </div>
  <div class="d-flex align-items-center justify-content-center h-100" *ngIf="showProgressSpinModalDanfe">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>
