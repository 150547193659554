import { cnpj } from 'cpf-cnpj-validator';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { CertificadoEmpresa } from 'src/model/certificado-empresa.model';
import { EmpresaService } from 'src/services/empresas.service';

@Component({
  selector: 'app-form-certificado-digital',
  templateUrl: './form-certificado-digital.component.html',
  styleUrls: ['./form-certificado-digital.component.scss']
})
export class FormCertificadoDigitalComponent {

  @Input() cnpj: string | undefined;
  @Input() certificado: CertificadoEmpresa = new CertificadoEmpresa();
  @Output() certificadoChange = new EventEmitter<CertificadoEmpresa>();
  @Input() label: string = 'Certificado A1';
  @Input() somenteVisualizar = false;

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  uploadedFiles: any[] = [];

  senhaCertificado: string = '';
  certificadoCarregado?: File;

  showModalCertificado: boolean = false;

  mensagemErro = "";

  constructor(
    private empresaService: EmpresaService
  ) { }

  onSenhaInput(event: any): void {
    const input = event.target as HTMLInputElement;
    this.senhaCertificado += input.value.slice(-1);
  }

  openModalCertificado() {
    this.senhaCertificado = '';
    this.certificadoCarregado = undefined;
    this.fileUpload.clear();
    this.showModalCertificado = true;
    setTimeout(() => {
      $("#mensagem-erro-preenchimento").hide();
      $("#mensagem-erro-senha").hide();
    }, 0);
  }

  removeCertificado() {
    this.certificadoChange.emit(undefined);
  }

  closeModalCertificado() {
    this.showModalCertificado = false;
  }

  getDadosCertificado() {
    if (this.certificadoCarregado && this.senhaCertificado) {
      this.empresaService.validaEObtemDadosCertificado(this.certificadoCarregado, this.senhaCertificado, this.cnpj!).subscribe({
        next: (data) => {
          this.certificado = new CertificadoEmpresa();
          this.certificado.byteArray = data.certificado;
          this.certificado.senha = data.senha;
          this.certificado.validoAPartirDe = data.validoAPartirDe;
          this.certificado.validoAte = data.validoAte;
          this.certificadoChange.emit(this.certificado);
          this.closeModalCertificado();
        },
        error: (response) => {
          if (response.error.message == 'keystore password was incorrect') {
            this.mensagemErro = "Senha do certificado incorreta"
          } else {
            this.mensagemErro = response.error.message;
          }
          $("#mensagem-erro-senha").show();
        }
      });
    }
  }

  onCarregadoCertificado(event: any) {
    if (event.files[0]) {
      $("#mensagem-erro-preenchimento").hide();
      this.certificadoCarregado = event.files[0];
    }
  }

  onRemoveCertificado() {
    this.certificadoCarregado = undefined;
  }

  onChangeSenhaCertificado() {
    $("#mensagem-erro-preenchimento").hide();
  }

  cancelarCarregarCertificado() {
    this.closeModalCertificado();
  }
}
