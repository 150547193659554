import { Page } from '../model/page.model';
import { MessageResponse } from '../model/message-response.model';
import { DadosCertificado } from '../model/dados-certificado.model';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { EmpresaPagedRequest } from 'src/model/empresa-paged-request.model';
import { Empresa } from 'src/model/empresa.model';

const ENDPOINT_EMPRESAS = environment.API_MAIN + "/empresas";
const ENDPOINT_EMPRESA = environment.API_MAIN + "/empresa";
const ENDPOINT_VALIDA_E_GET_DADOS_CERTIFICADO = environment.API_MAIN + "/empresa/valida-certificado";
const ENDPOINT_GET_DADOS_CERTIFICADO = environment.API_MAIN + "/empresa/certificado/dados";

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) {}

  /*
    Obtem lista paginada de empresas pelos filtros passados
  */
  public getEmpresasByFilters(request: EmpresaPagedRequest): Observable<Page<Empresa>> {
    const url = ENDPOINT_EMPRESAS + "?" + this.getParams(request).toString();
    return this.http.get<Page<Empresa>>(url);
  }

  /*
    Cria um novo registro de empresa
  */
  public criaEmpresa(empresa: Empresa): Observable<MessageResponse> {
    let formData: FormData = new FormData();
    formData.append('empresa', new Blob([JSON.stringify(empresa)], { type: 'application/json' }));
    return this.http.post<MessageResponse>(ENDPOINT_EMPRESA, formData);
  }

  /*
    Atualiza um registro de empresa
  */
  public atualizaEmpresa(empresa: Empresa | undefined): Observable<MessageResponse> {
    let formData: FormData = new FormData();
    formData.append('empresa', new Blob([JSON.stringify(empresa)], { type: 'application/json' }));
    return this.http.put<MessageResponse>(ENDPOINT_EMPRESA, formData);
  }

  /*
    Valida e obtem os dados do certificado
  */
  public validaEObtemDadosCertificado(certificado: File, senha: String, cnpj: string): Observable<DadosCertificado> {
    let formData: FormData = new FormData();
    formData.append('file', certificado, certificado.name);
    formData.append('senha', senha.toString());
    formData.append('cnpj', cnpj);
    return this.http.post<DadosCertificado>(ENDPOINT_VALIDA_E_GET_DADOS_CERTIFICADO, formData);
  }

  /*
    Obtem alguns dados do certificado
  */
  public getDadosCertificado(idEmpresa: string): Observable<DadosCertificado> {
    return this.http.get<DadosCertificado>(ENDPOINT_GET_DADOS_CERTIFICADO + "?idEmpresa=" + idEmpresa);
  }

  private getParams(request: Object): URLSearchParams {
    let params = new URLSearchParams();
    const keys = Object.keys(request);
    const values = Object.values(request);
    for (let i = 0; i < keys.length; i++) {
        if (values[i] instanceof Date) {
            params.set(keys[i], new Date(values[i]).toISOString());
        } else {
            params.set(keys[i], values[i]);
        }
    }
    return params;
  }
}
