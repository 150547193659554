import { CFeStatus } from './../../model/enums/cfe-status.enum';
import { AuthService } from './../../services/auth.service';
import { Observable, catchError, retry, throwError, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { GlobalEventService } from 'src/shared/global-event.service.ts.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class Interceptor implements HttpInterceptor {

  private maxRetry = 40;
  private delayInSeconds = 2;
  private lastErrorTime: { [serviceName: string]: number } = {};

  httpStatusRetentativas = [0, HttpStatusCode.ServiceUnavailable]

  constructor (
    private authService: AuthService,
    private globalEventService: GlobalEventService,
    private messageService: MessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    const token = this.authService.getToken();

    if (token) {
      request = request.clone({
        headers: request.headers.append(
          'Authorization', 'Bearer ' + token
        )
      });
    }

    if (!request.url.includes('/notificacoes') && !request.url.includes('/login')) {
      return next.handle(request).pipe(
        retry({
          count: this.maxRetry,
          delay: (_error, retryCount) => {
            if (this.httpStatusRetentativas.includes(_error.status)) {
              this.globalEventService.modalProcessandoEvent.emit(true);
              console.log(`Tentativa ${retryCount}/${this.maxRetry}, aguardando ${this.delayInSeconds} segundos antes de tentar novamente.`);
              return timer(this.delayInSeconds * 1000);
            } else {
              this.globalEventService.modalProcessandoEvent.emit(false);
              return throwError(() => _error);
            }
          }
        }),
        catchError(error => { // Quando finalizar todas as retentativas
          this.globalEventService.modalProcessandoEvent.emit(false);
          const serviceName = this.extractServiceName(error.url);
          const now = Date.now();
          const lastTime = this.lastErrorTime[serviceName] || 0;
          const interval = now - lastTime;
          if (interval > 10000) {
            this.lastErrorTime[serviceName] = now;
            if (error.status === 0 || error.status >= HttpStatusCode.InternalServerError) {
              this.showMensagemErro('Serviço indisponível', `O serviço "${serviceName}" está indisponível. Tente novamente mais tarde.`);
            }
          }
          console.error(error);
          return throwError(() => error);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private extractServiceName(url: string): string {
    const match = url.match(/^https?:\/\/[^\/]+\/([^\/]+)/);
    return match ? match[1] : 'desconhecido';
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }
}
