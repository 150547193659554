import { Injectable, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificacaoModel } from "src/model/notificacao.model";
import { AuthService } from "./auth.service";
import { EventSourcePolyfill } from 'event-source-polyfill';
import { HttpClient } from "@angular/common/http";

const GET_NOTIFICACOES = environment.API_MAIN + "/notificacoes";
const PUT_MARCA_COMO_LIDAS = environment.API_MAIN + "/notificacoes/marca-como-lidas";
const DELETE_NOTIFICACOES = environment.API_MAIN + "/notificacoes/limpar-notificacoes";

@Injectable({
  providedIn: 'root'
})
export class NotificacaoEventSourceService implements OnDestroy {

  public sse: EventSource | undefined;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  ngOnDestroy(): void {
    if (this.sse) {
      this.sse.close();
      console.debug("Event Source fechado");
    }
  }

  public connect(): Observable<NotificacaoModel[]> {

    var primeiraExecucao = true;
    var token = this.authService.getToken();
    var clienteLogin = this.authService.getUsuario()?.login;

    if (this.sse) {
      this.sse?.close();
    }

    var newEventSource = function(clienteLogin: string, dataRegistroAPartirDe?: string): EventSource {
      var paramData = "";
      if (dataRegistroAPartirDe) {
        paramData = "&dataRegistro=" + dataRegistroAPartirDe;
      }
      return new EventSourcePolyfill(GET_NOTIFICACOES + "?clienteLogin=" + clienteLogin + paramData, {
        headers: {
          Authorization: "Bearer " + token,
          "Connection": "keep-alive"
        }
      });
    }

    this.sse = newEventSource(clienteLogin!, undefined);
    var sse = this.sse;

    sse.onmessage = function(event) {
      console.log('Mensagem recebida: ', event.data);
    };

    return new Observable((observer) => {
      var listenerNotificationEvent = function (event: MessageEvent<any>) {
        var notificacoes: NotificacaoModel[] = JSON.parse(event.data);
        if (notificacoes.length > 0) {
          const dataRegistro = notificacoes[notificacoes.length - 1].dataRegistro;
          sse.close();
          sse = newEventSource(clienteLogin!, String(dataRegistro));
          sse.addEventListener('notification-event', listenerNotificationEvent, false);
          observer.next(event.data);
        } else if (primeiraExecucao) {
          observer.next([]);
        }
        primeiraExecucao = false;
      }

      sse.addEventListener('notification-event', listenerNotificationEvent, false);

      sse.onerror = (e: any) => {
        if (e.target.readyState !== sse.CONNECTING) {
          var notificacao = new NotificacaoModel();
          notificacao.mensagem = "Ocorreu erro na conexão com o serviço de notificações.";
          notificacao.modulo = "Erro";
          observer.error(notificacao);
          observer.complete();
        } else {
          var notificacao = new NotificacaoModel();
          notificacao.mensagem = "Tentando reconectar com o serviço de notificações...";
          notificacao.modulo = "Erro";
          observer.next(Array.of(notificacao));
        }
      };

      return () => {
        sse.close();
      };
    });
  }

  public marcaComoLidas(ids: String[]): Observable<any> {
    return this.http.put<any>(PUT_MARCA_COMO_LIDAS, ids);
  }

  public limparNotificacoes(ids: String[]): Observable<any> {
    return this.http.post<any>(DELETE_NOTIFICACOES, ids);
  }
}
