import { Title } from '@angular/platform-browser';
import { AuthService } from './../../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, EventEmitter, Injectable, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Periodo } from 'src/model/periodo.model';
import { Dropdown } from 'primeng/dropdown';
import { Cliente } from '../../../model/cliente.model';
import { Empresa } from 'src/model/empresa.model';
import { EmpresaService } from 'src/services/empresas.service';
import { EmpresaPagedRequest } from 'src/model/empresa-paged-request.model';
import { GlobalEventService } from 'src/shared/global-event.service.ts.service';
import { Subscription } from 'rxjs';
import { ClienteService } from 'src/services/clientes.service';
import { ClientesPagedRequest } from 'src/model/clientes-paged-request.model';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';
import { Page } from 'src/model/page.model';
import moment from 'moment';
import { cnpj } from 'cpf-cnpj-validator';

@Injectable()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  @ViewChild("campoPesquisaEmpresa", {static: false}) campoPesquisaEmpresa!: Dropdown;

  @Output() empresaChange = new EventEmitter<Empresa>();
  @Output() periodoChange = new EventEmitter<Periodo>();

  loadingComboEmpresas = true;

  clientesCombobox: Cliente[] = [];
  ClienteTipo = ClienteTipo;

  selectedMenu = "";
  nomeCliente = "";
  tooltipNomeCliente = "";

  items: MenuItem[];

  userLogged: Cliente | null;

  empresasDropdown: Empresa[] = [];
  labelNenhumaEmpresaEncontrada = "";
  selectedEmpresa?: Empresa;

  empresaRequest: EmpresaPagedRequest = new EmpresaPagedRequest();

  periodo: Periodo = new Periodo();

  timeout: any;
  minDate?: Date | null;
  maxDate = new Date();

  subscriptionPeriodoChange: Subscription | undefined;

  isEnableCfePage            = true;
  isEnableCtePage            = true;
  isEnableNfePage            = true;
  isEnableNfsePage           = true;
  isEnablePortalNacionalPage = true;
  previousBottonDisabled: boolean = false;
  nextBottonDisabled: boolean = false;
  today = new Date();

  constructor(
    private title: Title,
    private router: Router,
    public authService: AuthService,
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private globalEventService: GlobalEventService,
    private clienteService: ClienteService
  ) {
    this.title.setTitle('e-Reobot');
    this.userLogged = authService.getUsuario();

    if (authService.getUsuario()?.login.toUpperCase() == 'ADMIN') {
      if (this.router.getCurrentNavigation()?.finalUrl?.toString() == '/') {
        this.router.navigate(["cadastro-clientes"]);
      }
    }

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedMenu = (<NavigationEnd> val).url;
        if (this.selectedMenu == "/") {
          this.router.navigate(["dashboard"]);
        }
      }
    });

    this.items = [{
      label: '',
      items: [
        {
          label: 'Configurações',
          icon: 'pi pi-cog',
          command: () => {
            this.showMensagemInfo();
          },
          visible: false
        },
        {
          label: 'Sair',
          icon: 'pi pi-power-off mr-3',
          command: () => {
            this.logout();
          }
        }
      ]
    }];
  }

  delayParaAjusteTamanhoTela: any;

  ngOnInit(): void {
    this.nomeCliente = String(this.authService.getUsuario()?.nome);
    const login = String(this.authService.getUsuario()?.login);
    if (this.nomeCliente.length > 60) {
      this.nomeCliente = this.nomeCliente.substring(0, 59) + "...";
      this.tooltipNomeCliente = this.nomeCliente + "\n" + login;cnpj.format
    } else {
      const loginFormatado = cnpj.format(login);
      this.tooltipNomeCliente = loginFormatado ? loginFormatado : login;
    }

    const periodoStoraged: Periodo = JSON.parse(localStorage.getItem('periodo') || 'null');
    if (periodoStoraged) {
      this.periodo.dataInicio = new Date(periodoStoraged.dataInicio!);
      this.periodo.dataFim = new Date(periodoStoraged.dataFim!);
    } else {
      const date = new Date();
      this.periodo.dataInicio = new Date(date.getFullYear(), date.getMonth(), 1);
      this.periodo.dataFim = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    this.empresaRequest.unpaged = true;
    this.empresaRequest.ativa = true;
    this.empresaRequest.sortBy = ["nome;asc"];

    if (this.authService.getUsuario()?.clienteTipo != ClienteTipo.ADMIN) {
      this.empresaRequest.clienteLogin = this.userLogged?.login;
      this.loadDropdownEmpresas();
    } else {
      this.loadComboBoxClientes();
    }

    window.addEventListener("resize", () => {
      clearTimeout(this.delayParaAjusteTamanhoTela);
      this.delayParaAjusteTamanhoTela = setTimeout(() => this.ajustarAlturaDivConteudo(), 50);
    });
    this.ajustarAlturaDivConteudo();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedMenu = (<NavigationEnd> val).url;

        if (this.selectedMenu === "/cte" && !this.isEnableCtePage) {
          this.router.navigate(["/"]);
        }
        if (this.selectedMenu === "/cfe" && !this.isEnableCfePage) {
          this.router.navigate(["/"]);
        }
        if (this.selectedMenu === "/nfse" && !this.isEnableNfsePage) {
          this.router.navigate(["/"]);
        }
        if (this.selectedMenu === "/dfe" && !this.isEnableNfePage) {
          this.router.navigate(["/"]);
        }
        if (this.selectedMenu === "/portal-nfse" && !this.isEnablePortalNacionalPage) {
          this.router.navigate(["/"]);
        }
      }
      this.minDate = undefined;
    });

    this.globalEventService.nfseOpenPageEvent.subscribe({
      next: (nfseOpenPage: boolean) => {
        if (nfseOpenPage) {
          if (this.selectedEmpresa) {
            let empresaDataInicio: Date | undefined;
            if (this.selectedEmpresa.modulos.nfseModulo.dataRetroativaNFSe) {
              empresaDataInicio = new Date(this.selectedEmpresa.modulos.nfseModulo.dataRetroativaNFSe);
            } else {
              empresaDataInicio = new Date(this.selectedEmpresa.dataRegistro ?? new Date());
            }
            this.bloquearPeriodo(empresaDataInicio);
          }
        } else {
          this.bloquearPeriodo(new Date(1900, 0, 1));
        }
      }
    });

    this.globalEventService.savedEmpresaEvent.subscribe(empresa => {
      this.onSavedEmpresa(empresa);
      console.log("Atualizado lista de empresas");
    })

    this.periodoChange.subscribe(periodo => {
      localStorage.setItem('periodo', JSON.stringify(periodo));
    });
  }

  private ajustarAlturaDivConteudo() {
    var conteudo: HTMLElement | null = document.querySelector('.conteudo');
    var conteudoTop = conteudo?.getBoundingClientRect().top;
    if (conteudoTop && conteudo) {
      const style = window.getComputedStyle(document.body);
      const zoomValue: number = (style as any).zoom
      if (zoomValue == 1) {
        conteudo.style.height = ((window.innerHeight * ((1 - zoomValue) + 1)) - (110)) + 'px';
      } else  if (zoomValue == 0.9) {
        conteudo.style.height = ((window.innerHeight * ((1 - zoomValue) + 1)) - (zoomValue * 100)) + 'px';
      } else if (zoomValue == 0.8) {
        conteudo.style.height = ((window.innerHeight * ((1 - zoomValue) + 1)) - (zoomValue * 80)) + 'px';
      } else {
        conteudo.style.height = ((window.innerHeight * ((1 - zoomValue) + 1)) - (zoomValue * 40)) + 'px';
      }
    }
  }

  private loadComboBoxClientes() {

    let clienteRequest  = new ClientesPagedRequest();
    clienteRequest.ativo = true;
    clienteRequest.clienteTipo = ClienteTipo.SUPORTE;
    clienteRequest.unpaged = true;
    clienteRequest.sortBy = ["nome;asc"];

    this.showModalLoading();

    this.clienteService.getClientesByFilters(clienteRequest).subscribe({
      next: (data: Page<Cliente>) => {
        this.clientesCombobox = data.content;
        this.hideModalLoading();
      },
      error: (e: { name: string; message: string; }) => {
        this.showMensagemErro(e.name, e.message);
        console.error(e);
      }
    }).add(() => {
      this.hideModalLoading();
    });
  }

  onChangeCliente(event: any) {
    this.empresaRequest.clienteLogin = event.value.login;
    this.selectedEmpresa = undefined;
    this.loadDropdownEmpresas();
  }

  private loadDropdownEmpresas() {
    this.showModalLoading();
    // Chama API que traz todas as empresas do cliente logado
    this.empresaService.getEmpresasByFilters(this.empresaRequest).subscribe({
      next: (empresas) => {
        this.hideModalLoading();
        this.empresasDropdown = [];
        empresas.content.forEach((emp) => {
          let empresa = new Empresa();
          empresa.id = emp.id;
          empresa.suporteLogin = emp.suporteLogin;
          empresa.nome = emp.nome;
          empresa.modulos = emp.modulos;
          empresa.dataRegistro = emp.dataRegistro;
          if (empresa.modulos.nfseModulo.dataRetroativaNFSe) {
            empresa.modulos.nfseModulo.dataRetroativaNFSe = moment(empresa.modulos.nfseModulo.dataRetroativaNFSe).toDate();
          }
          this.empresasDropdown.push(empresa);
        });

        if (this.empresasDropdown.length === 1) {
          this.selectedEmpresa = this.empresasDropdown[0];
          this.campoPesquisaEmpresa.onChange.emit();
          this.empresaChange.emit(this.selectedEmpresa);
        } else {
          const empresa: Empresa = JSON.parse(localStorage.getItem('selectedEmpresa') || 'null');
          if (empresa) {
            const empresaIndex = this.empresasDropdown.findIndex(emp => emp.id == empresa.id);
            if (empresaIndex > -1) {
              this.selectedEmpresa = this.empresasDropdown[empresaIndex];
              this.campoPesquisaEmpresa.onChange.emit();
              this.empresaChange.emit(this.selectedEmpresa);
            }
          }
        }

        if (this.empresasDropdown.length == 0) {
          this.labelNenhumaEmpresaEncontrada = "Nenhuma empresa ativa encontrada";
        }
        this.loadingComboEmpresas = false;
      },
      error: (e) => {
        if (e.error.message) {
          this.showMensagemErro("Erro", e.error.message);
        } else {
          this.showMensagemErro(e.name, e.message);
        }
      }
    }).add(() => {
      this.hideModalLoading();
    });
  }

  private onSavedEmpresa(empresa: Empresa) {
    const index = this.empresasDropdown.findIndex(emp => emp.id == empresa.id);
    if (index == -1) {
      this.empresasDropdown = [empresa, ...this.empresasDropdown];
    } else {
      this.empresasDropdown[index] = empresa;
      this.selectedEmpresa = empresa;
    }
  }

  public getSelectedEmpresa() {
    return this.selectedEmpresa;
  }

  cleanFieldFilter() {
    setTimeout(() => {
      this.campoPesquisaEmpresa.filterValue = "";
    });
  }

  public showModalLoading(exibiImediatamente?: boolean): boolean {
    if (!this.timeout) {
      if (exibiImediatamente) {
        this.globalEventService.modalProcessandoEvent.emit(true);
        return false;
      } else {
        this.timeout = setTimeout(() => {
          this.globalEventService.modalProcessandoEvent.emit(true);
        }, 2000);
      }
    }
    return true;
  }

  public hideModalLoading(): boolean {
    clearTimeout(this.timeout);
    this.timeout = null;
    this.globalEventService.modalProcessandoEvent.emit(false);
    return false;
  }

  onChangeEmpresa(event: any) {
    localStorage.setItem('selectedEmpresa', JSON.stringify(this.selectedEmpresa));
    if (this.selectedEmpresa) {
      this.isEnableCfePage              = this.selectedEmpresa.modulos.cfeModulo?.cfeEnable;
      this.isEnableCtePage              = this.selectedEmpresa.modulos.cteModulo?.cteEnable;
      this.isEnableNfePage              = this.selectedEmpresa.modulos.nfeModulo?.nfeEnable;
      this.isEnableNfsePage             = this.selectedEmpresa.modulos.nfseModulo?.nfseEnable;
      this.isEnablePortalNacionalPage   = this.selectedEmpresa.modulos.portalNacionalModulo?.portalNacionalEnable;

      this.isEnableCfePage = this.selectedEmpresa.modulos.cfeModulo?.cfeEnable;
      this.isEnableCtePage = this.selectedEmpresa.modulos.cteModulo?.cteEnable;
      this.isEnableNfePage = this.selectedEmpresa.modulos.nfeModulo?.nfeEnable;
      this.isEnableNfsePage = this.selectedEmpresa.modulos.nfseModulo?.nfseEnable;
      this.isEnablePortalNacionalPage = this.selectedEmpresa.modulos.portalNacionalModulo?.portalNacionalEnable;

      if (this.router.url == "/nfse") {
        let empresaDataInicio: Date | undefined;
        if (this.selectedEmpresa.modulos.nfseModulo.dataRetroativaNFSe) {
          empresaDataInicio = new Date(this.selectedEmpresa.modulos.nfseModulo.dataRetroativaNFSe);
        } else {
          empresaDataInicio = new Date(this.selectedEmpresa.dataRegistro ?? new Date());
        }
        this.bloquearPeriodo(empresaDataInicio);
      }

      this.periodoChange.emit(this.periodo);
      if (event) {
        this.empresaChange.emit(event.value);
      }
    }
  }

  private bloquearPeriodo(data: Date | undefined) {
    setTimeout(() => {
      this.minDate = new Date(data!.getFullYear(), data!.getMonth(), 1);
      if (this.periodo.dataInicio) {
        if (this.periodo.dataInicio < this.minDate) {
          this.periodo.dataInicio = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), 1);
          this.periodo.dataFim = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 1, 0);
        }
      }
      this.updateLeftButtonState();
    });
  }

  onChangePeriodo(periodoRecebido: Date | undefined) {
    this.periodo.dataInicio = new Date(periodoRecebido!.getFullYear(), periodoRecebido!.getMonth(), 1);
    this.periodo.dataFim = new Date(this.periodo.dataInicio.getFullYear(), this.periodo.dataInicio.getMonth() + 1, 0);
    this.periodoChange.emit(this.periodo);

    this.updateLeftButtonState();
  }

  setMonthPrev() {
    if (this.periodo.dataInicio) {
      this.periodo.dataInicio.setMonth(this.periodo.dataInicio.getMonth() - 1);
      this.periodo.dataInicio = new Date(this.periodo.dataInicio.getFullYear(), this.periodo.dataInicio.getMonth(), 1);
      this.periodo.dataFim = new Date(this.periodo.dataInicio.getFullYear(), this.periodo.dataInicio.getMonth() + 1, 0);
      this.periodoChange.emit(this.periodo);
    }

    this.updateLeftButtonState();
  }

  setMonthNext() {
    if (this.periodo.dataInicio) {
      this.periodo.dataInicio.setMonth(this.periodo.dataInicio.getMonth() + 1);
      this.periodo.dataInicio = new Date(this.periodo.dataInicio.getFullYear(), this.periodo.dataInicio.getMonth(), 1);
      this.periodo.dataFim = new Date(this.periodo.dataInicio.getFullYear(), this.periodo.dataInicio.getMonth() + 1, 0);
    }
    this.periodoChange.emit(this.periodo);

    this.updateLeftButtonState();
  }

  updateLeftButtonState() {
    if (this.minDate && this.periodo.dataInicio) {
      this.previousBottonDisabled = this.periodo.dataInicio <= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), 1);
    }
  }

  bloqueioNextBottom(): boolean {
    return (this.periodo.dataFim?.getFullYear() == this.today.getFullYear() && this.periodo.dataFim.getMonth() == this.today.getMonth());
  }

  goToPageDfe() {
    this.selectedMenu = "/dfe";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageCte() {
    this.selectedMenu = "/cte";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageCfe() {
    this.selectedMenu = "/cfe";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageNFSe() {
    this.selectedMenu = "/nfse";
    this.router.navigate([this.selectedMenu]);
  }

  goToPagePortalNfSe() {
    this.selectedMenu = "/portal-nfse";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageRelatorio() {
    this.selectedMenu = "/relatorios";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageDashboard() {
    this.selectedMenu = "/dashboard";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageMinhasEmpresas() {
    this.selectedMenu = "/minhas-empresas";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageMinhaConta() {
    this.selectedMenu = "/minha-conta";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageCadastroClientes() {
    this.selectedMenu = "/cadastro-clientes";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageCadastroEmpresas() {
    this.selectedMenu = "/cadastro-empresas";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageConfiguracaoNfse() {
    this.selectedMenu = "/nfse-config";
    this.router.navigate([this.selectedMenu]);
  }

  goToPageImportarExportar() {
    this.selectedMenu = "/importar-exportar-xml";
    this.router.navigate([this.selectedMenu]);
  }

  private showMensagemInfo() {
    this.messageService.add({
      severity: 'info',
      summary: 'Em implementação',
      detail: ''
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  logout() {
    this.authService.logout();
  }

}
