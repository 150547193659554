<div class="d-flex flex-row ">
  <div class="me-4">
    <div class="area-sino">
      <p-button class="sino" />
      <div class="button-notification">
        <div>
          <p-badge [value]="qtdNovasNotificacoes" [styleClass]="qtdNovasNotificacoes ? 'p-badge-warning': 'p-overlay-danger'"></p-badge>
          <i class="ms-2 pi pi-bell"></i>
        </div>
      </div>
    </div>

    <div id="notification-list" class="p-listbox notification-list" [ngStyle]="{'display': esconderNotificacoes ? 'none': 'block'}">
      <div class="d-flex w-100 justify-content-end mt-2">
        <p-button label="Limpar" [rounded]="true" [link]="true" severity="secondary" (click)="limparNotificacoes()"/>
      </div>
      <ul *ngFor="let item of notificacoes" class="p-listbox-list mb-0">
        <li class="p-listbox-item p-ripple shadow-sm"
          [ngClass]="{'tipo-msg-erro': item.tipoMensagem == 'ERRO', 'tipo-msg-aviso': item.tipoMensagem == 'AVISO', 'tipo-msg-sucesso': item.tipoMensagem == 'SUCESSO'}">
          <div class="d-flex flex-row">
            <div class="notification-module">
              <b [ngStyle]="{'font-size': item.modulo!.length >= 8 ? '11px' : '13px'}">{{item.modulo}}</b>
            </div>
            <div class="notification w-100">
              <div><b>{{item.titulo}}</b></div>
              <hr *ngIf="item.mensagem">
              <div class="notification" [innerHTML]="item.mensagem"></div>
              <div class="notification" *ngIf="item.empresaCnpj">
                <table style="margin-top: .2rem !important;">
                  <tr>
                    <td>
                      <b>CNPJ</b>
                    </td>
                    <td>
                      <b style="color: rgb(155, 155, 155);">{{formatCnpjCpf(item.empresaCnpj)}}</b>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="notification-time d-flex justify-content-end w-auto text-black-50">{{obterTempoCorrido(item.dataRegistro)}}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
